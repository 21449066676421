import React, { useState } from 'react'
import Loader from "react-loader"
import axios from 'axios'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import TopMenu from '../../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../../parts/TopMenu/SideMenu.parts'

import { Container, Button, Card, Badge, Table, Spinner } from 'react-bootstrap'

//import * as Constants from '../../../Constants/Constants'

import { useMount } from '../../../helpers/lifecycle-hooks'
import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import { getToken, setUserRoles, handleMenuContent, checkVersion, getUrl } from '../../../Utils/Common'
import { login } from '../../../authProvider'

import FilterPanel from '../../../sharedComponents/filterPanel/FilterPanel.part'

//import MUIDataTable from "mui-datatables"

import Pagination from 'react-js-pagination'


function CustomersOpen(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [opening, setOpening] = useState(false)
  const [openingId, setOpeningId] = useState('')
  const [error, setError] = useState(null)

  const [stateNewFilter, setStateNewFilter] = useState(false)

  // Nastavení práv pro velkou tabulku
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
  // eslint-disable-next-line
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  const rightsBHtest:boolean = ((localStorage.getItem('BigHedgehog')! === '9')) ? true : false

  // Připojení store
  const rootStore = useStore()
  
  // Funkce pro zavření firmy
  const handleClose = async (isActive:boolean) => {
    setError(null)
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    
    if (isActive === true) {
      // Zavolání API
      await axios.get(getUrl('URL_CUSTOMER_CLOSE')+localStorage.getItem('openedCustomerId'),{
        headers: {
          'Authorization': 'Bearer ' + aToken
        }}
      ).then(response => {
        (async () => {
        // Nastavení nových práv a předání tokenu doplňku pro jejich řízení
        setUserRoles(response.data.roles)
        login(response.data.token)
        // Smazání id otevřené firmy z proměnné v localStore
        localStorage.removeItem('openedCustomerId')
        // Smazání otevřené firmy ze store
        await rootStore.deleteCustomerOpened()
        await rootStore.deleteCustomerOfferCount()
        localStorage.removeItem('openedFromOverviews')
        
        })();
      }).catch(error => {
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
        
      });
    }
    else {
      // Smazání id otevřené firmy z proměnné v localStore
      localStorage.removeItem('openedCustomerId')
      // Smazání otevřené firmy ze store
      await rootStore.deleteCustomerOpened()
      await rootStore.deleteCustomerOfferCount()
      localStorage.removeItem('openedFromOverviews')
    }
  }

  // Při otevření této stránky asynchronní zavolání
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    //setLoadedSide(false)
    window.scrollTo(0, 0)
    localStorage.setItem('lastOpenedSite', 'customers-open')
    await checkVersion()
    setStateNewFilter(true)


    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else { 
      //if (localStorage.getItem('openedCustomerId') !== null) {  
        //await handleClose(rootStore.customerOpened[0].isActive) //!!! vyřešit.... isactive je undefined - předřadit načtení?
        //setLoadedSide(true)
      //}
      //else { setLoadedSide(true) }
      if (rightsBHtest === true) await rootStore.fetchOverviewsCustomers() 
      
      if (rights) {
        if (Object.keys(rootStore.customerListAll).length < 100) await rootStore.fetchCustomerListAll('', '', 1)

      }

      await rootStore.fetchCustomerListNew(Number(sessionStorage.getItem('customersSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('customersSearchPage')))
      //await rootStore.fetchCustomerList(sessionStorage.getItem('customersSearchValue')!, sessionStorage.getItem('customersSearchTypeValue')!,Number(sessionStorage.getItem('customersSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('customersSearchPage')))  
      //Pokud je vyvolaná F5
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          //if (localStorage.getItem('openedCustomerId') !== null) {
            //await rootStore.fetchCustomerOpened()
          //}
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      }
      //Konec sekce F5

      //Pokud mám nastaveno ID otevřeného zákazníka
      if (localStorage.getItem('openedCustomerId') !== null) {
        //Pokud mi store nevrátí nic pro získání otevřeného zákazníka, tak přenačtu store
        if (rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!) === undefined) { 
          await rootStore.fetchCustomerOpened() 
          //await rootStore.fetchCustomerOfferCount()
        }
        // Firmu pak zavřeme
        await handleClose(rootStore.customerOpened[0].isActive !== undefined ? rootStore.customerOpened[0].isActive : false)
      }  
      
    }
    if (rootStore.loggedUsers[0] === undefined) {   
      //sessionStorage.setItem('automaticLogout', '1')
      //props.history.push('/logout')
    }
    if (sessionStorage.getItem('automaticLogout') === '2') {   
      //sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }    
    //console.log(rootStore.loggedUsers[0])   
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })

  //Počet záznamů
  const countData:number = Object.keys(rootStore.customerList).length

  // Seznam zákazníku do tlačítek
  const customerListData = rights !== true && rootStore.customerList.map(customerList => (
    <div key={customerList.id}>
    <Button variant="primary" size="lg" block onClick={() => handleOpen(customerList.id,customerList.isActive)}>   
        <span className="firmOpenFirstLine">
          <strong>{customerList.businessName}</strong>
        </span>
        <br />
        <span className="firmOpenSecondLine">
          Číslo zákazníka: {customerList.accountNumber}, IČO: {customerList.companyRegistrationNumber}
        </span>
      </Button>
      <br />
      </div>
  )) 

  // Seznam zákazníků do karet
  const customerListDataCard = rootStore.customerList.map(customerList => (
    <div key={customerList.id}>
    <Card>
      <Card.Body>
          <div className="float-right">
            <Button variant={customerList.isActive === true ? 'primary' : 'primary'} size="sm" style={{minWidth: "120px"}} disabled={opening && openingId === customerList.id} onClick={() => { handleOpen(customerList.id,customerList.isActive) } } >{opening && openingId === customerList.id ? 'Otevírám' : 'Otevřít'}</Button>
          </div>
          <strong>{customerList.businessName}</strong><br />
          <Badge variant="info">Číslo zákazníka: {customerList.accountNumber}</Badge>
          <br />
          {customerList.isActive === false ? <Badge variant="danger">Neaktivní</Badge>:<Badge variant="info">Aktivní</Badge>}
          <br /><i>
          IČO: {customerList.companyRegistrationNumber}
      </i>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))

  // Seznam zákazníků do řádků tabulky
  const customerListDataTable = rootStore.customerList.map(customerList => (
    <tr key={customerList.id}>
      <td><strong>{customerList.accountNumber}</strong></td>
      <td>
        {!opening &&<span className="as-link-no-color" onClick={() => { handleOpen(customerList.id,customerList.isActive) } }>{customerList.businessName}</span>}
        {opening &&<span className="as-link-no-color">{customerList.businessName}</span>}
      </td>
      <td>{customerList.companyRegistrationNumber}</td>
      <td>{customerList.isActive === false ? <Badge variant="danger">Neaktivní</Badge>:<Badge variant="info">Aktivní</Badge>}</td>
      <td><Button variant={customerList.isActive === true ? 'primary' : 'primary'} size="sm" style={{minWidth: "120px"}} disabled={opening && openingId === customerList.id} onClick={() => { handleOpen(customerList.id,customerList.isActive) } } >{opening && openingId === customerList.id ? 'Otevírám...' : 'Otevřít'}</Button></td>
    </tr>
  ))


  // Funkce po otevření firmy
  const handleOpen = async (id:string,isActive:boolean) => {
    setError(null)
    setOpening(true)
    setOpeningId(id)
    
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    
    if (isActive === true) {
      // Zavolání API
      await axios.get(getUrl('URL_CUSTOMER_OPEN')+id,{
        headers: {
          'Authorization': 'Bearer ' + aToken
        }}
      ).then(response => {
        (async () => {
        
        // Nastavení nových práv a předání tokenu doplňku pro jejich řízení
        setUserRoles(response.data.roles)
        login(response.data.token)

        // Nastavení id otevřené firmy do proměnné v localStore
        localStorage.setItem('openedCustomerId', id)

        // Načtení otevřené firmy do store
        await rootStore.fetchCustomerOpened()
        await rootStore.fetchCustomerOfferCount()
        ////if (rights === true) await rootStore.fetchCustomerOpenedCategories()
        ////if (rights === true && isActive) await rootStore.fetchCustomerOpenedLicenses(id)

        setOpening(false)

        // Přesměrování na stránku s přehledem firmy
        await props.history.push('/customers-dashboard')
        })();
      }).catch(error => {
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)

        setOpening(false)
      });
    }
    else {
      localStorage.setItem('openedCustomerId', id)
      await rootStore.fetchCustomerOpened()
      //await rootStore.fetchCustomerOfferCount()
      ////if (rights === true) await rootStore.fetchCustomerOpenedCategories()
      //if (rights === true && isActive) await rootStore.fetchCustomerOpenedLicenses(id)
      setOpening(false)
      await props.history.push('/customers-dashboard')
    }


  }

  const handlePageChange = (pageNumber:number) => {
    (async () => { 
      setLoading(true);
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      sessionStorage.setItem('customersSearchPage', String(pageNumber))
      // Předat hledanou hodnotu action pro načtení zákazníků
      await rootStore.fetchCustomerListNew(pageNumber)
      setLoading(false);
      // Hodnoty hledání uložit do sessionStorage
      
    })()
  }



  
  return (
    <div className="App">

        <TopMenu />
        <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
        <div id="layoutSidenav">
        
          <SideMenu />
          
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            
              <Container fluid>
                <br />
                
                {rights === true && <div>

                  

                  <FilterPanel 
                    //isCustomer={props.isCustomer} 
                    filterPage={'customerOpen'} 
                    filterSearchArray='customerOpenSearchArray' 
                    pageVariable='customersSearchPage' 
                    setPageLoading={setLoading} 
                    filterItems={['CustomerFulltext','AccountNumber','CompanyRegistrationNumber','Email']}  
                    title={rights === true ? 'Seznam zákazníků' : 'Seznam firem' }
                    titleCount={Number(localStorage.getItem('customersTotalCount')!)}
                    stateNewFilter={stateNewFilter}
                    setStateNewFilter={setStateNewFilter}
                    />

                  <br />
                  
                </div>}

                {(rights !== true && localStorage.getItem('customersTotalCountInit') === '1') && <div>
                  <div className="auth-wrapper">
                    <div className="auth-inner">  

                      <h4>Otevřít firmu</h4>
                      <br />
                      {customerListData}
                      
                    </div>
                  </div>
                </div>}

                <div className={rights === true || localStorage.getItem('customersTotalCountInit') !== '1' ? '' : 'nodisplay'}> 
                  <div className={rights === true ? 'nodisplay' : ''}>
                    <h4>Otevřít {rights === true ? 'zákazníka' : 'firmu'}</h4>
                    <br />
                  </div>

                  {(loading === true) 
                    ? 
                    <><Spinner animation='border'  />&nbsp;<br /></>
                    :
                    <>
                      <div className="showMobile">
                      {countData === 0 ? <div>Seznam zákazníků neobsahuje žádné záznamy.<br /><br /></div> : ''}
                          {customerListDataCard}
                      </div>
                      <div className="showWeb">
                        <Table responsive hover>
                          <thead>
                            <tr className="jsw-table-head">
                              <th className="jsw-table-head row-slim">Zákazník</th>
                              <th className="jsw-table-head">Název</th>
                              <th className="jsw-table-head">IČO</th>
                              <th className="jsw-table-head">Stav</th>
                              <th className="jsw-table-head">Akce</th>
                            </tr>
                          </thead>
                          <tbody>
                            {customerListDataTable}
                          </tbody>
                        </Table> 
                        {countData === 0 ? <div>Seznam zákazníků neobsahuje žádné záznamy.<br /><br /></div> : ''}
                      </div>  
                      <br />  
                      <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={Number(sessionStorage.getItem('customersSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('customersSearchPage')) }
                        itemsCountPerPage={10}
                        totalItemsCount={Number(localStorage.getItem('customersTotalCount'))}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange.bind(1)}
                        />
                     </>}   
                </div>
                {error}
              </Container>
              
            </main>
          </div>
        </div>
      </Loader>
    </div>
  );
}

export default observer(CustomersOpen)