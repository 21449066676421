import React, { useState, useEffect, useRef }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'


import { getToken, setUserRoles, getUrl } from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Row, CardDeck, Container, Badge, Spinner, ButtonGroup, Table, Card } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { useStore, useDialsStore } from '../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import { login } from '../../../authProvider'
import { ROUTES } from '../../../Routes'

import { TextEditor } from "../../../Utils/draft-js/TextEditor";

import CustomersDashboardInformation from '../../customers/dashboard/components/Customers.Dashboard.Information.part'
import CustomersDashboardDeliveryAddress from '../../customers/dashboard/components/Customers.Dashboard.DeliveryAddress.part'
import CustomersDashboardContacts from '../../customers/dashboard/components/Customers.Dashboard.Contacts.part'
import CustomersDashboardCategories from '../../customers/dashboard/components/Customers.Dashboard.Categories.part'
import CustomersDashboardLicenses from '../../customers/dashboard/components/Customers.Dashboard.Licenses.part'
import CustomersDashboardNotes from '../../customers/dashboard/components/Customers.Dashboard.Notes.part'

import CustomersFilesDetail from '../../customers/dashboard/components/Customers.Dashboard.Files.Modal.part'

import CustomersNamesUpdate from '../../../parts/customersOtherActions/Customers.OtherActions.NamesReplace.part'
import LoadFreeText from '../../../sharedComponents/loadFreeText/LoadFreeText.part'


//import CustomersDashboardNotesUpdate from '../../customers/dashboard/components/Customers.Dashboard.Notes.Update.part'

import moment from 'moment'


interface SupportContactsAddProps {
  isCustomer?:boolean,
  customerId?:string
  editing?:boolean,
  supportId?:string    
}

const SupportContactsAdd: React.FC<SupportContactsAddProps & RouteComponentProps> = (props:any) => {
  
  useEffect(() => {
    return () => {
      deleteInterval()
      deleteAllThemes()
    }
  }, [])

  const deleteInterval = async () => {
    for (var i = 0; i < 99999; i++)
	    clearInterval(i);
  }

  
  const editorReference = useRef();
  const refSubject = useRef<HTMLInputElement | null>(null);

  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false) 
  const [showNotes, setShowNotes] = useState(false)  
  const [themes, setThemes] = useState([""])
  const [showRequest, setShowRequest] = useState(false)
  const [showSolve, setShowSolve] = useState(false)
  const [showSolution, setShowSolution] = useState(true)
  const [showInvoice, setShowInvoice] = useState(false)
  const [opening, setOpening] = useState(false)
  const [insertNewContact, setInsertNewContact] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [contactLenght, setContactLenght] = useState(0)

  const [statusActive, setStatusActive] = useState(false)
  const [statusGDPR, setStatusGDPR] = useState(false)
  const [statusVS, setStatusVS] = useState(false)
  const [statusNewCustomer, setStatusNewCustomer] = useState(false)
  const [statusStart, setStatusStart] = useState(false)
  const [statusNonActualV, setStatusNonActualV] = useState(false)
  const [statusNotes, setStatusNotes] = useState(false)
  const [statusFiles, setStatusFiles] = useState(0)

  const [loadingData, setLoadingData] = useState(false)

  const [hasSTEREO, setHasSTEREO] = useState(false)

  const [stereoVer, setStereoVer] = useState('')
  const [duelVer, setDuelVer] = useState('')
  
  // eslint-disable-next-line
  const [hasDUEL, setHasDUEL] = useState(false)

  const [selectedCustomer, setSelectedCustomer] = useState('')
  const [selectedCustomerId, setSelectedCustomerId] = useState('')

  const [notes, setNotes] = useState([{date: '', note: '', customerId: '', id: '', isActive: false, isNotify : false, userId: '', authorId: ''}]) 

  const [loadingNoteId, setLoadingNoteId] = useState('') 
  
  // Připojení store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  //const customerList = rootStore.customerListAll

  const [customers, setCustomers] = useState(rootStore.customerListAll.filter(cl => cl.accountNumber?.includes('') || cl.businessName?.includes('')))  
  const [customerAllNames, setCustomerAllNames] = useState([""])
  const [customerNames, setCustomerNames] = useState(customerAllNames.filter(cn => cn !== ''))

  const [interval, startInterval] = useState(setInterval(() => {},1000000))

  const [customerDeepLoading, setCustomerDeepLoading] = useState(false)
  
  let currentUserArray = {id:''}
  currentUserArray = JSON.parse(localStorage.getItem('user')!)


  // Šipky u tlačítek pro rozevírání / sbalení
  const arrowDown = <svg className="svg-inline--fa fa-angle-double-down fa-w-10" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-double-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M143 256.3L7 120.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0L313 86.3c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.4 9.5-24.6 9.5-34 .1zm34 192l136-136c9.4-9.4 9.4-24.6 0-33.9l-22.6-22.6c-9.4-9.4-24.6-9.4-33.9 0L160 352.1l-96.4-96.4c-9.4-9.4-24.6-9.4-33.9 0L7 278.3c-9.4 9.4-9.4 24.6 0 33.9l136 136c9.4 9.5 24.6 9.5 34 .1z"></path></svg>
  const arrowUp = <svg className="svg-inline--fa fa-angle-double-up fa-w-10" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-double-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M177 255.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 351.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 425.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1zm-34-192L7 199.7c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l96.4-96.4 96.4 96.4c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9l-136-136c-9.2-9.4-24.4-9.4-33.8 0z"></path></svg>

  // Seznam typů kontaktu pro rozevírací seznam
  const supportTypes = rootDialsStore.dialsSupportTypes
  .slice()
  .filter(dst => dst.isActive === true)
  .sort(function(a:any, b:any) {
    var sort1A = a.order; 
    var sort1B = b.order; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  .map(dsp => (
    <option key={dsp.id} value={dsp.id}>{dsp.name}</option>
  ))   

  // Nastavení editivaného kontaktu
  const editedContact = rootStore.supports.find(s => s.id === props.supportId)

  // Seznam pracovníků JSW 
  const supportHedgehogs = rootStore.hedgehogUsers
   .filter(hu => hu.isEmailConfirmed === true)
   .slice()
   .sort(function(a:any, b:any) {
      var sort1A = a.surName; 
      var sort1B = b.surName; 

      if (sort1A! < sort1B!) {
        return -1;
      }
      if (sort1A! > sort1B!) {
        return 1;
      }
      return 0;
    })
   .map(hu => (
     <option key={hu.id} value={hu.id}>{hu.surName}&nbsp;{hu.firstName}</option>
   ))  

  // Seznam pracovníků neaktivní 
  const supportHedgehogsInactive = rootStore.hedgehogUsers
  .filter(hu => hu.isEmailConfirmed === false)
  .slice()
  .sort(function(a:any, b:any) {
    var sort1A = a.surName; 
    var sort1B = b.surName; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  .map(hu => (
    <option key={hu.id} value={hu.id} style={{color: 'lightgrey'}}>{hu.surName}&nbsp;{hu.firstName}</option>
  ))  

  // Otevírání a zavírání zobrazení formuláře
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    clearInterval(interval)
  }
  const handleCloseMain = () => {
    setInsertNewContact(false)
    setError(null)
    setFilesTU([])
    sessionStorage.removeItem('autoCloseModal')
    
    setStatusActive(false)
    setStatusGDPR(false)
    setStatusVS(false)
    setStatusNewCustomer(false)
    setStatusStart(false)
    setStatusNonActualV(false)
    setStatusNotes(false)
    setStatusFiles(0)

    setCustomerAllNames([""])
    setCustomerNames([""])
    setShowRequest(false)
    setShowSolve(false)
    setShowInvoice(false)
    setShowSolution(true)

    if (props.editing === true) {

    }
    else {
      /*
      localStorage.removeItem('supportSearchArray')
      sessionStorage.setItem('supportSearchPage', '1')
      */
      if (localStorage.getItem('supportSearchArrayLast') !== null) { localStorage.setItem('supportSearchArray', localStorage.getItem('supportSearchArrayLast')!) } else { localStorage.removeItem('supportSearchArray') }
      //localStorage.setItem('supportSearchArray', localStorage.getItem('supportSearchArrayLast')!)
      if (localStorage.getItem('supportSearchPageLast') !== null) { sessionStorage.setItem('supportSearchPage', localStorage.getItem('supportSearchPageLast')!) } else { sessionStorage.setItem('supportSearchPage', '1') }
      rootStore.fetchSupports(1,props.isCustomer)
    }
    clearInterval(interval)
    deleteAllThemes()
  }
  const handleShow = (id:string,isActive:boolean) => {
    sessionStorage.removeItem('autoCloseModal')
    handleOpen(id,isActive)
    setShow(true) 
  }

  const handleCloseNotes = () => {
    setShowNotes(false)
  }



  // Otevírání a zavírání modálního okna přidání souboru
  const handleShowFile = () => {
    //sessionStorage.removeItem('autoCloseModal')
    //setErrorFile(null)
    setShowInfoFile(false)
    setShowFormFile('')
    setShowFile(true)
  }
  const handleCloseFile = () => {
    setShowFile(false)
    setFileName('')
    setNewFile(null)
    //setErrorFile(null)
  }

  type FileArray = { file: any, fileName: string, note: string };
  const UNSUPPORTED_FORMATS = [".dzs", ".dzz", ".dgz", ".bak"];
  const FILE_SIZE = 20 * 1024 * 1024;

  const [filesTU, setFilesTU] = useState(new Array<FileArray>())
  // Nastavení stavů pro zobrazení
  const [loadingFile, setLoadingFile] = useState(false)
  //const [errorFile, setErrorFile] = useState(null)
  const [showInfoFile, setShowInfoFile] = useState(false)
  const [showFormFile, setShowFormFile] = useState('')
  const [showFile, setShowFile] = useState(false)

  const [newFile, setNewFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState(0);

  const setFile = async (evt:any) => {
    await setNewFile(evt.target.files[0]);
    await setFileName(evt.target.files[0].name);
    await setFileSize(evt.target.files[0].size);
  }



  const handleInsertFile = (filesData:string, noteData:string) => {
    //setErrorFile(null)
    setLoadingFile(true);
    
    let ftuO = filesTU
    ftuO.push({file: newFile, fileName: fileName, note: noteData})
    setFilesTU(ftuO)
    setLoadingFile(false)
    setShowFormFile('nodisplay')
    setShowInfoFile(true)
    setFileName('')
    setNewFile(null)
    // Po 1000 ms celé modální okno zavřeme
    setTimeout(() => {
        setShowFile(false)
        //setErrorFile(null)
        setShowInfoFile(false)
        setShowFormFile('')
    }, 1000);
    
  }

  // Schéma validace formuláře
  const schemaAddFile = Yup.object().shape({
    file: Yup.mixed() 
    .required("Soubor musí být vybrán!")
    .test('fileType', "Nepovolená přípona souboru (dzs, dzz, dgz, bak)", value => !UNSUPPORTED_FORMATS.includes(fileName.substr(fileName.length - 4, 4).toLowerCase()) )
    .test('fileSize', "Překročena maximální velikost souboru (20 MB)", value => fileSize <= FILE_SIZE) 

  })

  const uploadFiles = async(id:string) => {
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }

    setLoadingFile(true)

    const promises = filesTU.map(async(ftu) => {
      let data = new FormData() 
        data.set('linkId', id)
        data.set('note', ftu.note) 
        if (ftu.file !== null) data.append('file', ftu.file!)
        // Zavolání API
        await axios.post(getUrl('URL_FILES'), data, { headers: { 'Authorization': 'Bearer ' + aToken}}
          ).then(response => {
          
          // Změna dat ve Store
          //if (props.type === 'support') rootStore.fetchFilesForSupport(props.masterId)
        }).catch(error => {
        });
      })

    return Promise.all(promises)  
  }




  const startCounting = (startD:any) => {
    setStartDate(startD)
    //console.log(startDate)
    startInterval(setInterval(() => { setContactLenght(moment(new Date()).diff(startD, 'minutes'));  console.log(startD); },5000))
    //localStorage.setItem('lastInterval', String(interval))
    //console.log(interval)
  }





  // Funkce pro otevření firmy
  const handleOpen = async (id:string,isActive:boolean) => {
    setError(null)
    setOpening(true)

    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    
    if (isActive === true) {
      // Zavolání API
      await axios.get(getUrl('URL_CUSTOMER_OPEN')+id,{
        headers: {
          'Authorization': 'Bearer ' + aToken
        }}
      ).then(response => {
        (async () => {
        
        // Nastavení nových práv a předání tokenu doplňku pro jejich řízení
        setUserRoles(response.data.roles)
        login(response.data.token)

        // Nastavení id otevřené firmy do proměnné v localStore
        localStorage.setItem('openedCustomerId', id)

        // Načtení otevřené firmy do store
        await rootStore.fetchCustomerOpened()
        if (rights === true) rootStore.fetchCustomerOpenedCategories()
        if (rights === true) rootStore.fetchCustomerOpenedServices(id)
        if (rights === true) rootStore.fetchCustomerOpenedNotes(id)
        if (rights === true) rootStore.fetchFilesCountForOpenedCustomer();

        (async () => {
          // Pokud je zákazník aktivní, načítáme také licence a způsoby použití
          if (rootStore.getOpenedCustomerById(id!)?.isActive === true) {
            await rootStore.fetchCustomerOpenedLicenses(id!)
            rootStore.fetchUsageMode(id!,rootStore.getCustomerOpenedLicensesActiveActual()[0]?.license.program.id!,  rootStore.getCustomerOpenedLicensesActiveActual()[1]?.license.program.id!)
          }
        })()   
        setOpening(false)

        })();
      }).catch(error => {
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)

        setOpening(false)
      });
    }
    else {
      localStorage.setItem('openedCustomerId', id)
      await rootStore.fetchCustomerOpened()
      if (rights === true) rootStore.fetchCustomerOpenedCategories()
      if (rights === true) rootStore.fetchCustomerOpenedNotes(id)
      if (rights === true) rootStore.fetchFilesCountForOpenedCustomer()
      setOpening(false)
      
    }
  }


  // Funkce po odeslání formuláře
  const handleUpdate = (values:any) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      sessionStorage.setItem('lastSupportType',values.supportType) // Nastavení posledního použitého typu kontaktu

      console.log(values.supportType)

      let currentUser = {id:''}

      currentUser = JSON.parse(localStorage.getItem('user')!)

      let dataToSend:any

      // Pokud se jedná o editaci - nastavení dat pro odeslání
      if (props.editing === true) {
        dataToSend = {
          id: props.supportId,
          createDate: new Date(values.date).toISOString(),
          supportTypeId: values.supportType,
          isDemo: values.isDemo,
          customerId: props.isCustomer ? customer?.id : rootStore.customerListAll.find(cla => cla.accountNumber === values.customerId)?.id,
          accountNumber: props.isCustomer ? customer?.accountNumber :values.customerId,
          businessName: values.name,
          subject: values.subject,
          request: values.request,
          supportThemeIds: themes.filter(th => th !== ''),
          isTrouble: values.isTrouble,
          feeling: '1',
          isFinished: values.isFinished,
          duration: editedContact?.duration,
          solverUserId: editedContact?.solverUserId,
          deadLineDate: editedContact?.deadLineDate !== '' ? new Date(editedContact?.deadLineDate!).toISOString() : null,
          solutionCount: editedContact?.solutionCount
          }
      }
      else { // V případě přidání úplně nového kontaktu, posíláme více informací
        dataToSend = {
          createDate: new Date(values.date).toISOString(),
          supportTypeId: values.supportType,
          isDemo: values.isDemo,
          customerId: props.isCustomer ? customer?.id : rootStore.customerListAll.find(cla => cla.accountNumber === values.customerId)?.id,
          accountNumber: props.isCustomer ? customer?.accountNumber :values.customerId,
          businessName: values.name,
          subject: values.subject,
          request: values.request,
          supportThemeIds: themes.filter(th => th !== ''),
          isTrouble: values.isTrouble,
          feeling: '1',
          solverUserId: values.solverUserId === '' ? currentUser.id :values.solverUserId,
          deadLineDate: new Date(values.deadLineDate).toISOString(),
          isFinished: values.isFinished,
          duration: values.solutionDuration === 0 ? moment(new Date()).diff(startDate, 'minutes') === 0 ? 1 : moment(new Date()).diff(startDate, 'minutes') : values.solutionDuration,
          solution: {
            supportTypeId: values.solutionSupportTypeId,
            createDate: new Date(values.solutionDate).toISOString(),
            text: values.solText,
            isData: values.isData,
            toInvoice: values.toInvoice,
            duration: values.solutionDuration === 0 ? moment(new Date()).diff(startDate, 'minutes') === 0 ? 1 : moment(new Date()).diff(startDate, 'minutes') : values.solutionDuration,
          },
          solutionCount: 1
          }
      }

      // Nastavení adresy požadavku podle typu NOVÝ/EDITACE
      let urlForEditing:string = ''
      if (props.editing === true) urlForEditing = '/'+props.supportId

      // Zavolání API
      await axios.post(getUrl('URL_SUPPORT_CONTACTS_ADD')+urlForEditing, dataToSend , { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          (async () => {

            await uploadFiles(response.data.id)
            setLoadingFile(false)

            // Nastavovačky, co se má v případě, že je to OK, zobrazit
            setLoading(false)
            setFilesTU([])

            setShowForm('nodisplay')
            setShowInfo(true)
            setInsertNewContact(false)
            setSelectedCustomer('')
            sessionStorage.setItem('autoCloseModal','1')

            setStatusActive(false)
            setStatusGDPR(false)
            setStatusVS(false)
            setStatusNewCustomer(false)
            setStatusStart(false)
            setStatusNonActualV(false)
            setStatusNotes(false)
            setStatusFiles(0)
            //setThemes([''])
            
            setCustomerAllNames([""])
            setCustomerNames([""])
            setShowRequest(false)
            setShowSolve(false)
            setShowInvoice(false)
            setShowSolution(true)

            if (props.editing !== true) {
            //let supportSearchDataArray = [{filterName:"",filterValue:"",displayValue:""}] // Vyprázdnění předchozích filtrů
            //localStorage.setItem('supportSearchArray',JSON.stringify(supportSearchDataArray)) // Převedení pole na string do localStore
            //sessionStorage.setItem('supportSearchPage', '1') // Nastavení stránky seznamu na 1
            if (localStorage.getItem('supportSearchArrayLast') !== null) { localStorage.setItem('supportSearchArray', localStorage.getItem('supportSearchArrayLast')!) } else { localStorage.removeItem('supportSearchArray') }
            //localStorage.setItem('supportSearchArray', localStorage.getItem('supportSearchArrayLast')!)
            sessionStorage.setItem('supportSearchPage', localStorage.getItem('supportSearchPageLast')!)



            rootStore.fetchSupports(1,props.isCustomer) // Zavolání načtení kontaktů
            
            }
            else {
              rootStore.fetchSupportById(props.supportId)
              sessionStorage.setItem('contactDetailEdited', 'Y')
            }
            
            //sessionStorage.setItem('supportSearchPage', '1') // Nastavení stránky seznamu na 1
            //rootStore.fetchSupports(1,props.isCustomer) // Zavolání načtení kontaktů

            deleteAllThemes()
            //if (props.editing === true) rootStore.fetchSupportById(props.supportId)

            setTimeout(() => {
              if (props.editing === true) rootStore.fetchSupportById(props.supportId)
              clearInterval(interval)
            }, 1000);

            // Po 2800 ms celé modální okno zavřeme
            setTimeout(() => {
              if (props.editing === true) rootStore.fetchSupportById(props.supportId)
              if (sessionStorage.getItem('autoCloseModal') === '1') {  
                setShow(false)
                setShowForm('')
                setError(null)
                sessionStorage.removeItem('autoCloseModal')
                setShowInfo(false)
                //setShowForm('')
              }
            }, 2800);

          })()

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
        
    })()

  }

  // Schéma validace formuláře
  let schemaUpdate:any

  if (props.editing === true) {
    schemaUpdate = Yup.object().shape({
      subject: Yup.string()
      .required('Text předmětu musí být vyplněn!'),
      supportType: Yup.string()
      .required('Typ kontaktu musí být vyplněn!'),
    }) 
  }
  else {
    schemaUpdate = Yup.object().shape({
      solText: Yup.string()
      .required('Text řešení musí být vyplněn!'),
      subject: Yup.string()
      .required('Text předmětu musí být vyplněn!'),
      supportType: Yup.string()
      .required('Typ kontaktu musí být vyplněn!'),
      solutionSupportTypeId: Yup.string()
      .required('Typ kontaktu řešení musí být vyplněn!'),

    })  
  } 

// Deklarace a nastavení stavů pro seznamy ala google
const [state, setState] = useState({
  activeItem: 0,
  filteredItems: [],
  displayItems: false,
  inputValue: ''
})
const [stateName, setStateName] = useState({
  activeItem: 0,
  filteredItems: [],
  displayItems: false,
  inputValue: ''
})

const [showSolText, setShowSolText] = useState(true);
const [showReqText, setShowReqText] = useState(true);

// Výběry témat - přidání
const addTheme = (id:string) => {
  let themesOld = themes
  themesOld.push(id)
  setThemes(themesOld.filter(to => to !== "" || to !== id))
}

// Výběry témat - odebrání
const deleteTheme = (id:string) => {
  let themesOld = themes
  setThemes(themesOld.filter(to => to !== id))
}


// Filtrování zákazníků v seznamu
const filterCustomers = (value:string) => {
  //setCustomers(rootStore.customerListAll)
  //const newList = rootStore.customerListAll.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(value!.toLowerCase()))
  //setCustomers(newList)
  if (Object.keys(rootStore.customerListAll.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(value!.toLowerCase()))).length === 0 && value.length > 2 && value.length < 50 && customerDeepLoading === false) {

    (async () => {
      setCustomerDeepLoading(true)
      await rootStore.fetchCustomerListAllNoCache(value,'',1)
      //setCustomers(rootStore.getCustomerListAllByFilter(value))
      console.log(localStorage.getItem('customerQuickSearchValue'))
      setCustomers(rootStore.getCustomerListAllByFilter(localStorage.getItem('customerQuickSearchValue')!))
      setCustomerDeepLoading(false)
    })();
  }
  else {
    setCustomers(rootStore.getCustomerListAllByFilter(value))
  }
  //setCustomers(rootStore.getCustomerListAllByFilter(value))  
}

// Filtrování jmen v seznamu
const filterNames = (value:string) => {
  //setCustomers(rootStore.customerListAll)
  const newList = customerAllNames.filter(cn => cn !== '' && cn?.toLowerCase().includes(value!.toLowerCase()))
  setCustomerNames(newList)
}

// Funkce - nulování témat
const deleteAllThemes = async () => {
  setThemes([''])
}
 

// Startovací sequence při otevření formuláře
const startSequence = async () => {
  //setStartDate(new Date())
  await setThemes([''])
  if (props.editing === true) { // Pokud edituji, některé hodnoty mohu převzít
    if (editedContact?.customerId === rootStore.customerOpened[0]?.id!) {
      setSelectedCustomer(rootStore.customerOpened[0]?.businessName!)
    }
    else {
      setSelectedCustomer(rootStore.customerListAll.find(cla => cla.id === editedContact?.customerId)?.businessName!)
    }
    setSelectedCustomerId(editedContact?.customerId!)
    loadCustomerData(editedContact?.customerId!)
    // eslint-disable-next-line
    editedContact?.supportThemes!.slice().map(st => {
      addTheme(st)
    })    
  }

  if (props.isCustomer === true) {
    setSelectedCustomer(rootStore.customerOpened[0]?.businessName!)
    setSelectedCustomerId(rootStore.customerOpened[0]?.id!)
    loadCustomerData(rootStore.customerOpened[0]?.id!)
  }
  
  setInsertNewContact(true)
  //setStartDate(new Date()) 
  setShowInfo(false) 
  startCounting(new Date())
}


const startSequenceMain = async () => {
  await setThemes(['']);
  await deleteAllThemes();
  //await setStartDate(new Date()); 
  await setContactLenght(0); 
  await setSelectedCustomerId(''); 
  await setSelectedCustomer('');
  await setTimeout(async () => {
    await startSequence() // Načtení dat
  },10)
   
}


// Funkce pro načtení dat o ZÁKAZNÍKOVI
const loadCustomerData = async (customerId:string) => {
  await setLoadingData(true)
  
  setCustomerAllNames([]) // Vymazat případná předchozí jména načtená z minulého kontaktu

  setStateName({ // Nastavení stavu našeptávače v poli Osoba
    activeItem: 0,
    filteredItems: [],
    displayItems: false,
    inputValue: ''
  })

  // Kontrola na platnost přihlášení
  const aToken = await getToken()
  if ((aToken === null) || (aToken === undefined)) { 
    sessionStorage.setItem('automaticLogout', '1')
    props.history.push('/logout')
  }

  // Načtení jmen použitých v kontaktech
  await axios.get(getUrl('URL_SUPPORT_CONTACTS_ADD')+'/customer/'+customerId+'/names',{
    headers: {
      'Authorization': 'Bearer ' + aToken
    }  }).then(response => {
      
      setCustomerAllNames(response.data)
      setCustomerNames(response.data)

      setStateName({
        activeItem: 0,
        filteredItems: [],
        displayItems: props.editing !== true ? true:false,
        inputValue: ''
      })
  }).catch(error => {
    
  });

  // Načtení poznámek
  await axios.get(getUrl('URL_CUSTOMER_NOTES')+'/'+customerId+'/notes',{
    headers: {
      'Authorization': 'Bearer ' + aToken
    }  }).then(response => {

      // Nastavení zobrazení upozornění
      let countNotes:number = Object.keys(response.data.items.filter((n: {isNotify: boolean, isActive: boolean}) => n.isNotify === true && n.isActive === true )).length
      console.log(countNotes)
      if (countNotes !== 0) {
        setNotes(response.data.items.filter((n: {isNotify: boolean, isActive: boolean}) => n.isNotify === true && n.isActive === true ))
        setStatusNotes(true)
        if (props.editing !== true) setShowNotes(true)
      }
      else {
        setNotes([{date: '', note: '', customerId: '', id: '', isActive: false, isNotify : false, userId: '', authorId: ''}])
      }
      
      //setStatusVS(new Date(response.data.find((s: { service: { id: string }, validTo:string }) => s.service.id === 'dec69c57-bd93-4546-8272-3f93c080f794').validTo!.replace(' ', 'T')) > new Date() ? true:false)

  }).catch(error => {
    
  });

  // Načtení základních informací o zákazníkovi a nastavení stavů pro indikaci
  await axios.get(getUrl('URL_CUSTOMER_LIST')+'/'+customerId,{
    headers: {
      'Authorization': 'Bearer ' + aToken
    }  }).then(response => {

      setStatusGDPR(response.data.gdprDate! !== null ? new Date(response.data.gdprDate!.replace(' ', 'T')) > new Date() ? true:false : false)
      setStatusActive(response.data.isActive!)
      //console.log(response.data.isActive!)
      setStatusNewCustomer((response.data.createDate! === "" || response.data.createDate! === null) ? false : moment(new Date()).diff(response.data.createDate!.replace(' ', 'T'), 'days') < 91 ? true : false)

  }).catch(error => {
    
  });

  // Načtení služeb zákazníka
  await axios.get(getUrl('URL_CUSTOMER_SERVICES')+'/'+customerId+'/services',{
    headers: {
      'Authorization': 'Bearer ' + aToken
    }  }).then(response => {

      // Nastavení statusu VS
      setStatusVS(new Date(response.data.find((s: { service: { id: string }, validTo:string }) => s.service.id === 'dec69c57-bd93-4546-8272-3f93c080f794').validTo!.replace(' ', 'T')) > new Date() ? true:false)

  }).catch(error => {
    
  });

  // Načtení licencí zákazníka
  await axios.get(getUrl('URL_CUSTOMER_LICENSES')+''+customerId+'/licenses',{
    headers: {
      'Authorization': 'Bearer ' + aToken
    }  }).then(response => {
      
      let actualVersion:number = 0
      let newVersion:number = 0

      const licenseDuel   = response.data.find((l: { createDate: string, license: { program: { code: string }, isActive: boolean, status: string, programVersion: {supportDateTo: string, version: number} } }) => l.license.program.code === 'D' && l.license.isActive === true && l.license.status === 'A')
      const licenseStereo = response.data.find((l: { createDate: string, license: { program: { code: string }, isActive: boolean, status: string, programVersion: {supportDateTo: string, version: number} } }) => l.license.program.code === 'S' && l.license.isActive === true && l.license.status === 'A')
      
      if (licenseDuel !== undefined) {
        console.log(licenseDuel)
        console.log(licenseDuel.license.program.code)

        // Nastavení tématu kontaktu - DUEL
        if (licenseDuel.license.program.code !== null) { 
          
          if (props.editing !== true) {
            if ((licenseDuel.license.programVersion.supportDateTo === null || licenseDuel.license.programVersion.supportDateTo === '' || new Date(licenseDuel.license.programVersion.supportDateTo.replace(' ', 'T')) >= new Date() )) { 
              addTheme(rootDialsStore.dialsSupportThemes.find(st => st.name === 'Duel')!.id!) 
            }
            setHasDUEL(true)
          }
          setDuelVer('D'+String(licenseDuel.license.programVersion.version))
          
        }

        // Kontrola na aktuálnost licencí
        if (licenseDuel.license.programVersion.supportDateTo === null || licenseDuel.license.programVersion.supportDateTo === '' || new Date(licenseDuel.license.programVersion.supportDateTo.replace(' ', 'T')) >= new Date() ) { 
          actualVersion = actualVersion+1        
        }

        // Kontrola na novost licencí
        if (!(licenseDuel.createDate === null || licenseDuel.createDate === '') && moment(new Date()).diff(licenseDuel.createDate.replace(' ', 'T'), 'days') < 91 ) { 
          newVersion = newVersion+1        
        }

      }
      else {
        actualVersion = 0
        newVersion = 0
        deleteTheme(rootDialsStore.dialsSupportThemes.find(st => st.name === 'Duel')!.id!)
        setDuelVer('')
      }
      
      
      if (licenseStereo !== undefined) {
        console.log(licenseStereo)
        console.log(licenseStereo.license.program.code)
        
        // Nastavení tématu kontaktu - STEREO
        if (licenseStereo.license.program.code !== null) { 
          if (props.editing !== true) { 
            if ((licenseStereo.license.programVersion.supportDateTo === null || licenseStereo.license.programVersion.supportDateTo === '' || new Date(licenseStereo.license.programVersion.supportDateTo.replace(' ', 'T')) >= new Date() )) {   
              addTheme(rootDialsStore.dialsSupportThemes.find(st => st.name === 'Stereo')!.id!) 
            }
          }
          setHasSTEREO(true)
          setStereoVer('S'+String(licenseStereo.license.programVersion.version))
        }

        // Kontrola na aktuálnost licencí
        console.log(licenseStereo.license.programVersion.supportDateTo)
        if (licenseStereo.license.programVersion.supportDateTo === null || licenseStereo.license.programVersion.supportDateTo === '' || new Date(licenseStereo.license.programVersion.supportDateTo.replace(' ', 'T')) >= new Date() ) { 
          
          actualVersion = actualVersion+1        
        }

        // Kontrola na novost licencí
        if (!(licenseStereo.createDate === null || licenseStereo.createDate === '') && moment(new Date()).diff(licenseStereo.createDate.replace(' ', 'T'), 'days') < 91 ) { 
          newVersion = newVersion+1        
        }

      }
      else {
        deleteTheme(rootDialsStore.dialsSupportThemes.find(st => st.name === 'Stereo')!.id!)
        setStereoVer('')
      }

      if (actualVersion === 0) {setStatusNonActualV(true);} // Pokud nemá žádnou aktuální licenci - zapneme indikaci
      if (newVersion > 0) {setStatusNewCustomer(true);} // Pokud je nějaká licence nová - zapneme indikaci

  }).catch(error => { 
    
  });

  // Načtení kategorií zákazníka - status pro STARTVERZI
  await axios.get(getUrl('URL_CUSTOMER_CATEGORIES')+''+customerId+'/categories',{
    headers: {
      'Authorization': 'Bearer ' + aToken
    }  }).then(response => {
      console.log(response.data)
      setStatusStart(response.data.find((c: { id: string, code:string }) => c.code === 'S').id !== null ? hasSTEREO === true ?   false : true : false)
  }).catch(error => {
    
  });


  // Načtení základních řešení zákazníka
  let filter:string = '(linkId~eq~'+customerId+')'
  let order:string = 'CreatedDate~desc'
  
  const page = 0
  const pagesize = 0

  await axios.get(getUrl('URL_FILES'),{
    headers: {
      'Authorization': 'Bearer ' + aToken
    },params: {
      filter,
      order,
      page,
      pagesize
    }  }).then(response => {

    setStatusFiles(response.data.totalCount)
    
  }).catch(error => {

  });


  await axios.get(getUrl('URL_CUSTOMER_LIST')+'/'+customerId,{
    headers: {
      'Authorization': 'Bearer ' + aToken
    }  }).then(response => {

      setStatusGDPR(response.data.gdprDate! !== null ? new Date(response.data.gdprDate!.replace(' ', 'T')) > new Date() ? true:false : false)
      setStatusActive(response.data.isActive!)
      //console.log(response.data.isActive!)
      setStatusNewCustomer((response.data.createDate! === "" || response.data.createDate! === null) ? false : moment(new Date()).diff(response.data.createDate!.replace(' ', 'T'), 'days') < 91 ? true : false)

  }).catch(error => {
    
  });

  

  // Automatické nahození tématu
  //console.log(rootStore.getLoggedUserPreferenceByKey('supportDefaultTheme')?.key) 
  
  if (rootStore.getLoggedUserPreferenceByKey('supportDefaultTheme')?.key !== undefined) {
    if ( !(themes.includes(rootStore.getLoggedUserPreferenceByKey('supportDefaultTheme')?.value!)))
    addTheme(rootStore.getLoggedUserPreferenceByKey('supportDefaultTheme')?.value!) 
  }

  setLoadingData(false)

}


const removeWarningFromNote = async (id:string) => {

  const note = notes.find(n => n.id === id)

  setLoadingNoteId(note?.id!)

  // Kontrola na platnost přihlášení
  const aToken = await getToken()
  if ((aToken === null) || (aToken === undefined)) { 
    sessionStorage.setItem('automaticLogout', '1')
    props.history.push('/logout')
  }

  // Načtení poznámek
  await axios.post(getUrl('URL_CUSTOMER_NOTES')+'/'+note?.customerId+'/notes/'+note?.id, {id: note?.id, customerId: note?.customerId, note: note?.note, isNotify: false, isActive: note?.isActive} ,{
    headers: {
      'Authorization': 'Bearer ' + aToken
    }  }).then(response => {



  }).catch(error => {
    
  });

   

  // Načtení poznámek
  await axios.get(getUrl('URL_CUSTOMER_NOTES')+'/'+note?.customerId+'/notes',{
    headers: {
      'Authorization': 'Bearer ' + aToken
    }  }).then(response => {

      // Nastavení zobrazení upozornění
      let countNotes:number = Object.keys(response.data.items.filter((n: {isNotify: boolean, isActive: boolean}) => n.isNotify === true && n.isActive === true )).length
      //console.log(countNotes)
      if (countNotes !== 0) {
        setNotes(response.data.items.filter((n: {isNotify: boolean, isActive: boolean}) => n.isNotify === true && n.isActive === true ))
        setStatusNotes(true)
        //if (props.editing !== true) setShowNotes(true)
      }
      else {
        setStatusNotes(false)
        setShowNotes(false)
        setNotes([{date: '', note: '', customerId: '', id: '', isActive: false, isNotify : false, userId: '', authorId: ''}])
      }
      
      //setStatusVS(new Date(response.data.find((s: { service: { id: string }, validTo:string }) => s.service.id === 'dec69c57-bd93-4546-8272-3f93c080f794').validTo!.replace(' ', 'T')) > new Date() ? true:false)

  }).catch(error => {
    
  });

  await setLoadingNoteId('')

}

// Kontrola po výběru Typu kontaktu
const controlAfterSelectType = (idType:string) => {
  /*
  if (rootDialsStore.dialsSupportTypes.find(st => st.id === idType)?.data! !== null && rootDialsStore.dialsSupportTypes.find(st => st.id === idType)?.data!.includes('GDPR') === true) {
    if (statusGDPR === false) console.log('GDPR !!!')
  }

  if (rootDialsStore.dialsSupportTypes.find(st => st.id === idType)?.data! !== null && rootDialsStore.dialsSupportTypes.find(st => st.id === idType)?.data!.includes('VS') === true) {
    if (statusVS === false) console.log('VS !!!')
  }
  */
}

const setNewCustomer = (customerId:string,accountNumber:string,businessName:string) => {
 
  setStatusActive(false)
  setStatusGDPR(false)
  setStatusVS(false)
  setStatusNewCustomer(false)
  setStatusStart(false)
  setStatusNonActualV(false)
  setStatusNotes(false)
  setThemes([''])

  setCustomerAllNames([""])
  setCustomerNames([""])

  setSelectedCustomer(businessName);
  setSelectedCustomerId(customerId);

  setTimeout(async () => {
    await loadCustomerData(customerId) // Načtení dat

    if (props.editing !== true) {
      if (localStorage.getItem('supportSearchArray') !== null) { localStorage.setItem('supportSearchArrayLast', localStorage.getItem('supportSearchArray')!) } else { localStorage.removeItem('supportSearchArrayLast') }
      localStorage.setItem('supportSearchPageLast', (sessionStorage.getItem('supportSearchPage') === null || sessionStorage.getItem('supportSearchPage') === undefined) ? '1' : sessionStorage.getItem('supportSearchPage')!)

      let supportSearchDataArray = [{filterName:"",filterValue:"",displayValue:""}] // Vyprázdnění předchozích filtrů
      supportSearchDataArray.push({filterName: 'CustomerId', filterValue: customerId, displayValue: accountNumber! }) // Přidání podmínky do filtru
      localStorage.setItem('supportSearchArray',JSON.stringify(supportSearchDataArray)) // Převedení pole na string do localStore
      sessionStorage.setItem('supportSearchPage', '1') // Nastavení stránky seznamu na 1
      await rootStore.fetchSupports(1,props.isCustomer) // Zavolání načtení kontaktů
    }

  },10)
}


const scrollToBeVisible = function (ele:any, container:any) {
  
  if (ele !== null) {
    const eleTop = ele.offsetTop;
    const eleBottom = eleTop + ele.clientHeight;

    const containerTop = container.scrollTop;
    const containerBottom = containerTop + container.clientHeight;

    if (eleTop < containerTop) {
        // Scroll to the top of container
        container.scrollTop -= containerTop - eleTop;
    } else if (eleBottom > containerBottom) {
        // Scroll to the bottom of container
        container.scrollTop += eleBottom - containerBottom;
    }
  }
};

// Odchytávání kláves po stisknutí na údaji ZÁKAZNÍK
const handleKeyDownCustomer =  (e:any, setFieldValue:any, nextInput:number) => {
  const { activeItem, filteredItems } = state;
  
  if (props.editing !== true) setThemes(['']) // Pokud se nejedná o editaci - vymazat naklikaná témata

  if (e.keyCode === 13) {     //Stisknutá klávesa ENTER
    e.preventDefault();         // Vyrušit standardní funkci tlačítka

      // Pokud je pole prázdné, tak focus nepošlem na pole s indexem dle parametru funkce, ale na údaj Demo a ve funkci nepokračujeme
      if (!state.displayItems) { 
        var form = e.currentTarget.form;
        form.elements[nextInput-1].focus()
        return
      }  

      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: filteredItems[activeItem]
      });
      
      /*
      // Nastavíme hodnoty dalších polí a stavových proměnných
      setFieldValue('customerId',customers[activeItem].accountNumber)
      setFieldValue('businessName',customers[activeItem].businessName)
      setSelectedCustomer(customers[activeItem].businessName);
      setSelectedCustomerId(customers[activeItem].id);
      
      // Po pidichvilce načteme potřebná data o zákazníkovi a přefiltrujeme seznam kontaktů, pokud je potřeba
      setTimeout(async () => {
        await loadCustomerData(customers[activeItem].id) // Načtení dat

        let supportSearchDataArray = [{filterName:"",filterValue:"",displayValue:""}] // Vyprázdnění předchozích filtrů
        supportSearchDataArray.push({filterName: 'CustomerId', filterValue: customers[activeItem].id, displayValue: customers[activeItem].accountNumber! }) // Přidání podmínky do filtru
        localStorage.setItem('supportSearchArray',JSON.stringify(supportSearchDataArray)) // Převedení pole na string do localStore
        sessionStorage.setItem('supportSearchPage', '1') // Nastavení stránky seznamu na 1
        await rootStore.fetchSupports(1,props.isCustomer) // Zavolání načtení kontaktů

      },10)
      */
     if (customers[activeItem] !== undefined) {
        setFieldValue('customerId',customers[activeItem].accountNumber)
        setFieldValue('businessName',customers[activeItem].businessName)
        setNewCustomer(customers[activeItem].id,customers[activeItem].accountNumber!,customers[activeItem].businessName)
      }
      // Posun na pole s indexem dle parametru funkce
      if (e.target.nodeName === "INPUT") {
        form = e.currentTarget.form;
        form.elements[nextInput].focus()
      }

  }
  else if (e.keyCode === 27) { // ESC (Vyprázdnit)
      e.preventDefault();
      
      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: e.currentTarget.value
      });
      setFieldValue('customerId','')
      setFieldValue('businessName','')
  }

  else if (e.keyCode === 38) { // Šipka nahoru (posun v seznamu)
      e.preventDefault();
      if (activeItem === 0) {
          return;
      }

      setState({
          activeItem: activeItem - 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))
  }
  else if (e.keyCode === 40) { // Šipka dolu (posun v seznamu)
      e.preventDefault();
      if ((customers && activeItem === customers.length - 1) || activeItem >= 49) { // Pokud jsem na poslední pozici, již se nikam neposunu
        return;
      }

      setState({
          activeItem: activeItem + 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))
  }
  else if (e.keyCode === 73 && e.ctrlKey) { //CTRL + I (Otevření modálního okna s informacemi o zákazníkovi)
    e.preventDefault();
    if (selectedCustomerId !== '') {
    handleShow(rootStore.customerListAll.find(cla => cla.id === selectedCustomerId)?.id!, rootStore.customerListAll.find(cla => cla.id === selectedCustomerId)?.isActive!) 
    }
  }

};

// Odchytávání kláves po stisknutí na údaji OSOBA
const handleKeyDownNames =  (e:any, setFieldValue:any, nextInput:number) => {
  const { activeItem, filteredItems } = stateName;

  if (e.keyCode === 13) {     //Stisknutá klávesa ENTER
    e.preventDefault();         // Vyrušit standardní funkci tlačítka

      // Pokud je pole prázdné, pošleme focus dál a ve funkci nepokračujeme
      if (!stateName.displayItems) { 
        var form = e.currentTarget.form;
        form.elements[nextInput].focus()
        return
      }  

      setStateName({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: filteredItems[activeItem]
      });
      
      // Nastavíme hodnotu pole formuláře
      if (customerNames[activeItem] !== undefined) setFieldValue('name',customerNames[activeItem])
      
      // Pošleme focus dál
      if (e.target.nodeName === "INPUT") {
        var formN = e.currentTarget.form;
        formN.elements[nextInput].focus()
      }

  }
  else if (e.keyCode === 27) {    // ESC
      e.preventDefault();
      
      setStateName({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: e.currentTarget.value
      });
      
  }

  else if (e.keyCode === 38) {    // Šipka nahoru (posun v seznamu)
      e.preventDefault();
      if (activeItem === 0) {
          return;
      }

      setStateName({
          activeItem: activeItem - 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('peop'+String(activeItem-1)), document.getElementById('scrollpeople'))
  }
  else if (e.keyCode === 40) {    // Šipka dolu (posun v seznamu)
      e.preventDefault();
      if ((customerNames && activeItem === customerNames.length - 1) || activeItem >= 29) {  // Pokud jsem na poslední pozici, již se nikam neposunu
        //console.log('T')  
        return;
      }

      setStateName({
          activeItem: activeItem + 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('peop'+String(activeItem+1)), document.getElementById('scrollpeople'))
  }
  else if (e.keyCode === 73 && e.ctrlKey) { //CTRL + I (Otevření modálního okna s informacemi o zákazníkovi)
    e.preventDefault();
    console.log('DETAIL')
    if (selectedCustomerId !== '') {
    handleShow(rootStore.customerListAll.find(cla => cla.id === selectedCustomerId)?.id!, rootStore.customerListAll.find(cla => cla.id === selectedCustomerId)?.isActive!) 
    }
  }

};

// Odchytávání kláves po stisknutí s parametrem pro index následujícího pole ve formuláři
const handleKeyDown = (e:any, nextInput:number, handleSubmit:any) => {
  if (e.keyCode === 13) {       //Stisknutá klávesa ENTER
    e.preventDefault();           // Vyrušit standardní funkci tlačítka
    if (e.ctrlKey && !loading) {              // CTRL + ENTER?
      handleSubmit()              // Odeslat formulář
      return                      // Návrat z funkce zpět a nepokračovat dál  
    }

    // Nastavení další pozice kurzoru ve formuláři pokud je stisknutý ENTER
    var form = e.currentTarget.form;
    form.elements[nextInput].focus()
  }
  else if (e.keyCode === 73 && e.ctrlKey) { //CTRL + I (Otevření modálního okna s informacemi o zákazníkovi)
    e.preventDefault();
    console.log('DETAIL')
    if (selectedCustomerId !== '') {
    handleShow(rootStore.customerListAll.find(cla => cla.id === selectedCustomerId)?.id!, rootStore.customerListAll.find(cla => cla.id === selectedCustomerId)?.isActive!) 
    }
  }
  else if (e.keyCode === 82 && e.altKey) { //ALT + R 
    e.preventDefault();
    setShowSolution(!showSolution) 
  }
  else if (e.keyCode === 80 && e.altKey) { // ALT + P
    e.preventDefault();
    setShowRequest(!showRequest)
    
  }
  else if (e.keyCode === 74 && e.altKey) { // ALT + J
    e.preventDefault();
    setShowSolve(!showSolve)
  }
};

// Odchytávání kláves po stisknutí s parametrem pro korekci indexu následujícího pole ve formuláři
const handleKeyDownNextWIthCorrection = (e:any, correction:number, handleSubmit:any) => {
  
  if (e.keyCode === 13) {       //Stisknutá klávesa ENTER
    e.preventDefault();           // Vyrušit standardní funkci tlačítka
    if (e.ctrlKey && !loading) {              // CTRL + ENTER?  
      handleSubmit()              // Odeslat formulář
      return                      // Návrat z funkce zpět a nepokračovat dál
    }

    // Nastavení další pozice kurzoru ve formuláři pokud je stisknutý ENTER
    var form = e.currentTarget.form;
    var index = Array.prototype.indexOf.call(form, e.target);
    console.log(index)
    form.elements[index+1+correction].focus()
  }
  else if (e.keyCode === 73 && e.ctrlKey) { //CTRL + I (Otevření modálního okna s informacemi o zákazníkovi)
    e.preventDefault();
    if (selectedCustomerId !== '') {
    handleShow(rootStore.customerListAll.find(cla => cla.id === selectedCustomerId)?.id!, rootStore.customerListAll.find(cla => cla.id === selectedCustomerId)?.isActive!) 
    }
  }
  else if (e.keyCode === 82 && e.altKey) { //ALT + R
    e.preventDefault();
    setShowSolution(!showSolution)
    
  }
  else if (e.keyCode === 80 && e.altKey) { // ALT + P
    e.preventDefault();
    setShowRequest(!showRequest)
    
  }
  else if (e.keyCode === 74 && e.altKey) { // ALT + J
    e.preventDefault();
    setShowSolve(!showSolve)
    
  }
};

// Odchytávání kláves po stisknutí s parametrem pro korekci indexu následujícího pole ve formuláři
const handleKeyDownNextToRef = (e:any, ref:any, handleSubmit:any, editor:boolean) => {
  
  if (e.keyCode === 13) {       //Stisknutá klávesa ENTER
    e.preventDefault();           // Vyrušit standardní funkci tlačítka
    if (e.ctrlKey && !loading) {              // CTRL + ENTER?  
      handleSubmit()              // Odeslat formulář
      return                      // Návrat z funkce zpět a nepokračovat dál
    }

    // Nastavení další pozice kurzoru ve formuláři pokud je stisknutý ENTER
    var form = e.currentTarget.form;
    var index = Array.prototype.indexOf.call(form, e.target);
    console.log(index)
    
    editor === true ? ref.current.focusEditor() : ref.current.focus()

    //form.elements[index+1+correction].focus()
  }
  else if (e.keyCode === 73 && e.ctrlKey) { //CTRL + I (Otevření modálního okna s informacemi o zákazníkovi)
    e.preventDefault();
    if (selectedCustomerId !== '') {
    handleShow(rootStore.customerListAll.find(cla => cla.id === selectedCustomerId)?.id!, rootStore.customerListAll.find(cla => cla.id === selectedCustomerId)?.isActive!) 
    }
  }
  else if (e.keyCode === 82 && e.altKey) { //ALT + R
    e.preventDefault();
    setShowSolution(!showSolution)
    
  }
  else if (e.keyCode === 80 && e.altKey) { // ALT + P
    e.preventDefault();
    setShowRequest(!showRequest)
    
  }
  else if (e.keyCode === 74 && e.altKey) { // ALT + J
    e.preventDefault();
    setShowSolve(!showSolve)
    
  }
};


const handleKeyDownCtrlEnter = (e:any, handleSubmit:any) => {
  
  if (e.keyCode === 13) {       //Stisknutá klávesa ENTER
    e.preventDefault();           // Vyrušit standardní funkci tlačítka
    if (e.ctrlKey && !loading) {              // CTRL + ENTER?  
      handleSubmit()              // Odeslat formulář
      return                      // Návrat z funkce zpět a nepokračovat dál
    }

    
  }
  else if (e.keyCode === 73 && e.ctrlKey) { //CTRL + I (Otevření modálního okna s informacemi o zákazníkovi)
    e.preventDefault();
    if (selectedCustomerId !== '') {
    handleShow(rootStore.customerListAll.find(cla => cla.id === selectedCustomerId)?.id!, rootStore.customerListAll.find(cla => cla.id === selectedCustomerId)?.isActive!) 
    }
  }
  else if (e.keyCode === 82 && e.altKey) { //ALT + R
    e.preventDefault();
    setShowSolution(!showSolution)
    
  }
  else if (e.keyCode === 80 && e.altKey) { // ALT + P
    e.preventDefault();
    setShowRequest(!showRequest)
    
  }
  else if (e.keyCode === 74 && e.altKey) { // ALT + J
    e.preventDefault();
    setShowSolve(!showSolve)
    
  }
};

/* NEPOUŽITÉ FUNKCE
const handleKeyDownTextArea = (e:any, nextInput:number, handleSubmit:any) => {
  if (e.keyCode === 13 && e.ctrlKey) {    
      handleSubmit()
      return
  }
};

const focusToIndex = (e:any, nextInput:number) => {
  var form = e.currentTarget.form;
    form.elements[nextInput].focus()
};
*/

/*
let d = new Date();
let year:number = d.getFullYear();
let month:number = d.getMonth();
let monthTxt:string = month<9 ? '0':''
let day:number = d.getDate();
let dayTxt:string = day<10 ? '0':''
let todayTxt:string = String(year) + '-' + monthTxt + String(month+1) + '-' + dayTxt + String(day)
*/

// VYKRESLENÍ FORMULÁŘE S KONTAKTEM 
const contactForm = 
      <>
          <Alert variant="success" show={showInfo} style={{paddingTop: '20px'}}>
            <strong>Kontakt byl úspěšně přidán!​</strong>
            
          </Alert>  
          {insertNewContact &&<>
          <div className={showForm} >
          <Alert variant="info" > 
            
            <Formik
              validationSchema={schemaUpdate}
              validateOnChange={false}
              validateOnBlur={false}
              validateOnMount={props.validateOnMount}
              initialValues={{ 
                customerId: props.editing === true ?  editedContact?.accountNumber: props.isCustomer === true ? rootStore.customerOpened[0].accountNumber:'',
                name: props.editing === true ?  editedContact?.businessName:'', 
                supportType: props.editing === true ?  editedContact?.supportTypeId : sessionStorage.getItem('lastSupportType') === undefined || sessionStorage.getItem('lastSupportType') === null ? '':sessionStorage.getItem('lastSupportType') ,
                businessName: selectedCustomer,
                isDemo: props.editing === true ?  editedContact?.isDemo:false,
                subject: props.editing === true ?  editedContact?.subject:'',
                isTrouble: props.editing === true ?  editedContact?.isTrouble:false,
                feeling: props.editing === true ?  editedContact?.feeling:'',
                //duration: props.editing === true ?  editedContact?.duration:0,
                request: props.editing === true ?  editedContact?.request:'',
                //solverUserId: '',
                solverUserId: currentUserArray.id,
                deadLineDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
                //deadLineDate: todayTxt,
                //solutionSupportTypeId: '',
                solutionSupportTypeId: sessionStorage.getItem('lastSupportType') === undefined || sessionStorage.getItem('lastSupportType') === null ? '': rootDialsStore.dialsSupportTypes.find(st => st.id === sessionStorage.getItem('lastSupportType'))?.solutionDefaultTypeId === null ? '' : rootDialsStore.dialsSupportTypes.find(st => st.id === sessionStorage.getItem('lastSupportType'))?.solutionDefaultTypeId ,
                isData: false,
                toInvoice: false,
                solutionDuration: 0,
                solText: '',
                date: props.editing === true ?  moment(editedContact?.createDate).format("YYYY-MM-DDTHH:mm"):moment(startDate).format("YYYY-MM-DDTHH:mm"),
                solutionDate: moment(startDate).format("YYYY-MM-DDTHH:mm"),
                isFinished: props.editing === true ? editedContact?.isFinished : true
              
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values!)              
                  setSubmitting(false);
                }, 1);
              }}
              
              
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                validateField,
                

                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  
                  <Form.Row>
                    <Col lg={10}>



                  <Form.Row>
                    {props.isCustomer !== true &&
                    <>
                    <FormGroup as={Col} lg={2} md={3} sm={3} xs={12} controlId="customerId">
                      <FormLabel >Zákazník</FormLabel>
                        <FormControl 
                          type="text"
                          name="customerId"
                          value={values.customerId!}
                          onChange={async(e) => {await handleChange(e);  state.displayItems = true; state.activeItem = 0; await localStorage.setItem('customerQuickSearchValue', e.target.value);  await filterCustomers(e.target.value) }}
                          //tabIndex={1}
                          autoComplete="off"
                          autoFocus={true}
                          //disabled={disabledAN}
                          isValid={touched.customerId && !errors.customerId} 
                          isInvalid={!!errors.customerId}
                          onKeyDown={(e:any) => handleKeyDownCustomer(e, setFieldValue, 4)}
                          //id="customerId"
                        />
                        {values.customerId! !== '' && state.displayItems ?
                            <div id="scrollcustomer" className="list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
                                <ul className="uk-list" style={{ padding: '0px', marginTop: '0px', width: '400px', cursor: 'pointer'}}>
                                {
                                    customers.map((dp,index) => {
                                        return (
                                            <li id={'cust'+String(index)} className={`${state.activeItem === index ? "active-item" : "default-item"}`}
                                                onClick={async (e:any) => {await setFieldValue("customerId",dp.accountNumber); await setFieldValue("businessName",dp.businessName); state.displayItems = false; await setNewCustomer(dp.id,dp.accountNumber!,dp.businessName)}}
                                                key = {dp.id}
                                                >
                                                {dp.accountNumber} - {dp.businessName}
                                            </li>
                                        )
                                    }).slice(0, 50)
                                }
                                </ul>
                            </div>
                            : null
                        }
                        <Form.Control.Feedback type="invalid">
                          {errors.customerId}
                        </Form.Control.Feedback>
                    </FormGroup>

                    <FormGroup as={Col} lg={7} md={7} sm={9} xs={12}  controlId="businessName">
                      <FormLabel >Název zákazníka</FormLabel>
                      <label className="integratedButton">
                      <FormControl 
                          type="text"
                          name="businessName"
                          value={values.businessName!}
                          onChange={handleChange}
                          disabled={true}
                          isValid={touched.businessName && !errors.businessName} 
                          isInvalid={!!errors.businessName}
                        />
                        <span className="insideInput" >
                          <Button variant="primary" size="sm" title='Detail firmy (Ctrl + I)' className={values.businessName !== '' ? '' : 'nodisplay'} onClick={() => { handleShow(rootStore.customerListAll.find(cla => cla.accountNumber === values.customerId)?.id!, rootStore.customerListAll.find(cla => cla.accountNumber === values.customerId)?.isActive!)   }} ><i className="fas fa-info-circle"></i></Button>
                        </span>
                      </label>  
                      <Badge style={{marginRight: '5px'}} variant={selectedCustomerId === '' || loadingData === true ? 'light' : statusActive === false ? 'danger' : 'success'} title='Příznak, zda je zákazník aktivní.'>{statusActive === false ? 'Neaktivní' : 'Aktivní'}</Badge>
                      <Badge style={{marginRight: '5px'}} variant={selectedCustomerId === '' || loadingData === true ? 'light' : statusGDPR === false ? 'danger' : 'success'} title='Příznak, zda má zákazník podepsanou GDPR smlouvu.'>GDPR</Badge>
                      <Badge style={{marginRight: '5px'}} variant={selectedCustomerId === '' || loadingData === true ? 'light' : statusVS === false ? 'danger' : 'success'} title='Příznak, zda má zákazník předplacenou vzdálenou správu.'>VS</Badge>
                      <Badge style={{marginRight: '5px'}} variant={selectedCustomerId === '' || loadingData === true ? 'light' : statusNonActualV === false ? 'success' : 'danger'} title='Příznak, zda zákazník má alespoň jednu aktuální verzi programu.'>{statusNonActualV === false ? 'Aktuální' : 'Neaktuální'}{stereoVer !== '' && <>&nbsp;{stereoVer}</>}{duelVer !== '' && <>&nbsp;{duelVer}</>}</Badge>
                      <Badge style={{marginRight: '5px'}} variant={selectedCustomerId === '' || loadingData === true ? 'light' : statusStart === false ? 'light' : 'success'} title='Příznak, zda má zákazník DUEL registrovaný v režimu StartVerze.'>Startverze</Badge>
                      <Badge style={{marginRight: '5px'}} variant={selectedCustomerId === '' || loadingData === true ? 'light' : statusNewCustomer === false ? 'light' : 'success'} title='Příznak, zda se jedná o nového zákazníka (do 3 měsíců).'>Nový</Badge>
                      <Badge style={{marginRight: '5px', cursor: statusNotes === true ? 'pointer' : ''}} variant={selectedCustomerId === '' || loadingData === true ? 'light' : statusNotes === false ? 'light' : 'danger'} title='Příznak, zda má zákazník aktivní upozornění.' onClick={() => { if (statusNotes === true) setShowNotes(true)}}>Upozornění</Badge>
                      <CustomersFilesDetail customerId={rootStore.customerListAll.find(cla => cla.accountNumber === values.customerId)?.id!} showAs='badge' badgeFilesCount={statusFiles} />

                    </FormGroup> 
                    
                    <FormGroup as={Col} lg={1} controlId="isDemo">
                    <div style={{float: 'left', width: '80px',  textAlign: 'center'}}>
                      <FormLabel style={{display: 'block', marginBottom: '.85rem'}}>Demo</FormLabel>
                      
                        <FormControl 
                          type="checkbox"
                          name="isDemo"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isDemo}
                          //tabIndex={values.customerId !== '' ? undefined : 2}
                          onKeyDown={(e:any) => handleKeyDown(e, 5, handleSubmit)}
                          isValid={touched.isDemo && !errors.isDemo} 
                          isInvalid={!!errors.isDemo}

                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.isDemo}
                        </Form.Control.Feedback>
                      
                        </div>
                    </FormGroup>
                    </>}
                    </Form.Row>


                    <Form.Row>
                    <FormGroup as={Col} lg={3} md={4} sm={6}  controlId="name">
                      <FormLabel>Jméno</FormLabel>
                        <FormControl 
                          type="text"
                          name="name"
                          autoFocus={props.isCustomer === true ? true:false}
                          value={values.name!}
                          autoComplete="off"
                          //onChange={handleChange}
                          onChange={async(e) => {await handleChange(e);  stateName.displayItems = true; stateName.activeItem = 0; await filterNames(e.target.value) }}
                          //tabIndex={3}
                          onKeyDown={(e:any) => handleKeyDownNames(e, setFieldValue, props.isCustomer === true ? 1:5)}
                          onBlur={(e:any) => {setTimeout(() => {setStateName({activeItem: 0, filteredItems: stateName.filteredItems, displayItems: false, inputValue: stateName.inputValue}); console.log('a')},200 ) }}
                          //onBlur={(e:any) => {stateName.displayItems = false; console.log('a')}}
                          isValid={touched.name && !errors.name} 
                          isInvalid={!!errors.name}
                        />
                        {values.customerId! !== '' && stateName.displayItems ?
                            <div id="scrollpeople" className="list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
                                <ul className="uk-list" style={{padding: '0px', marginTop: '0px', width: '400px', cursor: 'pointer'}}>
                                {
                                    customerNames.map((cn,index) => {
                                        return (
                                            <li id={'peop'+String(index)} className={`${stateName.activeItem === index ? "active-item" : "default-item"}`}
                                                onClick={() => {console.log('X');console.log(cn);setFieldValue("name",cn);stateName.displayItems = false}}
                                                key = {index}
                                                >
                                                {cn}
                                                
                                            </li>
                                        )
                                    }).slice(0, 30)
                                }
                                </ul>
                            </div>
                            : null
                        }
                        {selectedCustomerId !== '' &&<>&nbsp;<CustomersNamesUpdate customerId={selectedCustomerId} label='Přejmenování osob' showAs='link'  /></>}
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                    </FormGroup> 
   
                    <FormGroup as={Col} md={3} sm={6} xs={12} controlId="supportType">
                      
                        <FormLabel>Typ kontaktu</FormLabel>
                          <Form.Control as="select" 
                            name="supportType" 
                            onChange={async(e) => {await handleChange(e); await controlAfterSelectType(e.target.value);  if (rootDialsStore.dialsSupportTypes.find(st => st.id === e.target.value)?.solutionDefaultTypeId !== '') { await setFieldValue("solutionSupportTypeId", rootDialsStore.dialsSupportTypes.find(st => st.id === e.target.value)?.solutionDefaultTypeId ) } }}
                            //onChange={handleChange}
                            value={values.supportType!}
                            isInvalid={!!errors.supportType}
                            onBlur={() => validateField('supportType')}
                            //tabIndex={4}
                            //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 4:8, handleSubmit)}
                            onKeyDown={(e:any) => handleKeyDownNextToRef(e, refSubject, handleSubmit, false)}
                            >
                              <option value="">--- Vyberte ---</option>
                              {
                              supportTypes
                              }
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.supportType}
                          </Form.Control.Feedback>
                     
                    </FormGroup>

                    <FormGroup as={Col} md={3} sm={6} xs={12} controlId="date">
                      
                        <FormLabel>Datum a čas</FormLabel>
                        
                          <FormControl 
                            type="datetime-local"
                            name="date"
                            value={values.date!}
                            onChange={handleChange}
                            isValid={touched.date && !errors.date} 
                            isInvalid={!!errors.date}
                            onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 1:1, handleSubmit)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.date}
                          </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} lg={1}  controlId="isTrouble">
                    <div style={{float: 'left', width: '80px',  textAlign: 'center'}}>
                      <FormLabel style={{display: 'block', marginBottom: '.85rem'}}>Problém</FormLabel>
                      
                        <FormControl 
                          type="checkbox"
                          name="isTrouble"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isTrouble}
                          //tabIndex={6}
                          onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 6:10, handleSubmit)}
                          isValid={touched.isTrouble && !errors.isTrouble} 
                          isInvalid={!!errors.isTrouble}

                          
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isTrouble}
                        </Form.Control.Feedback>
                      
                     </div>
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup as={Col} lg={9} md={10} sm={12}  controlId="subject">
                      <FormLabel>Předmět</FormLabel>
                        <FormControl 
                          type="text"
                          name="subject"
                          value={values.subject!}
                          onChange={handleChange}
                          //tabIndex={5}
                          onBlur={() => validateField('subject')}
                          onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 6:10, handleSubmit)}
                          isValid={touched.subject && !errors.subject} 
                          isInvalid={!!errors.subject}
                          ref={refSubject}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.subject}
                        </Form.Control.Feedback>
                    </FormGroup> 
                    
                    <FormGroup as={Col} lg={1} md={2} sm={3} xs={4} style={{display: 'none'}}  controlId="feeling">
                      <FormLabel>Pocit</FormLabel>
                        <FormControl 
                          type="text"
                          name="feeling"
                          value={values.feeling!}
                          onChange={handleChange}
                          //tabIndex={7}
                          onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 6:10, handleSubmit)}
                          isValid={touched.feeling && !errors.feeling} 
                          isInvalid={!!errors.feeling}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.feeling}
                        </Form.Control.Feedback>
                    </FormGroup>
                    
                    
                    
                  </Form.Row>
                  <>
                    {<Button 
                        variant='secondary' 
                        size='sm' 
                        title='Rozbalit popis (Alt+P)'
                        style={{lineHeight: '1.15', 
                        minWidth: '230px', 
                        marginBottom: '10px'}} 
                        
                        onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 2, handleSubmit)}
                        onClick={(e) => { setShowRequest(!showRequest); }}
                        >{!showRequest ? <span><div style={{float:'right'}}>{arrowDown}</div>Popis</span>:<><div style={{float:'right'}}>{arrowUp}</div>Skrýt popis</>}
                      </Button>}
                    <br />
                  </>

                    {showRequest &&
                    <Form.Row>
                    <FormGroup as={Col}  controlId="request">
                      <FormLabel>Popis</FormLabel>
                      {showReqText &&
                        <TextEditor
                          setFieldValue={(val) => setFieldValue("request", val)}
                          value={values.request!}
                          
                        />}
                        <div>
                          <LoadFreeText 
                            setFieldValue={setFieldValue} 
                            showFormField={setShowReqText} 
                            pole="request" 
                            originalText={values.request!} 
                            handleKeyDown={handleKeyDownCtrlEnter}
                            handleSubmit={handleSubmit}
                            />
                        
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.request}
                        </Form.Control.Feedback>
                    </FormGroup>
                    </Form.Row>
                    }

                    {props.editing !== true && <>
                      <Button 
                        variant="outline-success" 
                        size="sm" 
                        style={{lineHeight: '1.15', 
                          minWidth: '230px', 
                          marginBottom: '10px'}} onClick={handleShowFile}
                      >
                        <i className="fas fa-paperclip"></i>&nbsp;Připojit soubor
                      </Button>
                      <br />
                      {filesTU.map((ftu,index) => (
                        <div key={index}>
                        {ftu.fileName} <div className='btn btn-sm' onClick={() => setFilesTU(filesTU.filter((f) => f !== ftu))}>Smazat</div><br />
                        </div>
                      ))}
                    </>}
                  
                  {props.editing !== true && <>

                  {!showSolution &&
                  <>
                    <Button 
                        variant='secondary' 
                        size='sm' 
                        style={{lineHeight: '1.15', minWidth: '230px', marginBottom: '10px'}} 
                        onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                        onClick={() => {setShowSolution(!showSolution)}}
                        >{!showSolution ? <span><div style={{float:'right'}}>{arrowDown}</div>Řešení (Alt + R)</span>:<><div style={{float:'right'}}>{arrowUp}</div>Skrýt řešení  (Alt + R)</>}
                      </Button>
                    <br />
                  </>}
                  {showSolution &&
                    <>
                    <hr />
                    <Form.Row>
                    <strong>Řešení kontaktu</strong><br />
                    </Form.Row>
                    <Form.Row>
                      <FormGroup as={Col} lg={3} md={4} sm={6}  controlId="name">
                        <FormLabel>Jméno</FormLabel>
                          <FormControl 
                            type="text"
                            name="name"
                            value={values.name!}
                            onChange={handleChange}
                            disabled={true}
                            //tabIndex={5}
                            //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 6:10, handleSubmit)}
                            //isValid={touched.subject && !errors.subject} 
                            //isInvalid={!!errors.subject}
                          />
                          
                      </FormGroup> 

                      <FormGroup as={Col} md={3} sm={6} xs={12} controlId="solutionSupportTypeId">
                      <FormLabel>Typ kontaktu</FormLabel>
                        <Form.Control as="select" 
                          name="solutionSupportTypeId" 
                          //onChange={handleChange}
                          onChange={async (e) => { await handleChange(e); validateField('solutionSupportTypeId')}}
                          value={values.solutionSupportTypeId!}
                          isInvalid={!!errors.solutionSupportTypeId}
                          onBlur={() => validateField('solutionSupportTypeId')}
                          //tabIndex={undefined}
                          //onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 1, handleSubmit)}
                          onKeyDown={(e:any) => handleKeyDownNextToRef(e, editorReference, handleSubmit, true)}
                          >
                            <option value="">--- Vyberte ---</option>
                            {
                            supportTypes
                            }
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.solutionSupportTypeId}
                        </Form.Control.Feedback>
                    </FormGroup>

                      <FormGroup as={Col} md={3} sm={6} xs={12} controlId="solutionDate">
                        
                        <FormLabel>Datum a čas</FormLabel>
                        
                          <FormControl 
                            type="datetime-local"
                            name="solutionDate"
                            value={values.solutionDate!}
                            onChange={handleChange}
                            isValid={touched.solutionDate && !errors.solutionDate} 
                            isInvalid={!!errors.solutionDate}
                            //onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                            onKeyDown={(e:any) => handleKeyDownNextToRef(e, editorReference, handleSubmit, true)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.solutionDate}
                          </Form.Control.Feedback>
                      </FormGroup>
                    <Col lg={3} md={2} sm={6}>
                      <Row>
                    <Col lg={6} md={0}></Col>
                    
                    <FormGroup as={Col} lg={6} md={12} sm={6} xs={12}  controlId="solutionDuration">
                      <FormLabel>Doba&nbsp;({contactLenght}&nbsp;min.)</FormLabel>
                        <FormControl 
                          type="number"
                          name="solutionDuration"
                          value={values.solutionDuration!}
                          onChange={handleChange}
                          //tabIndex={undefined}
                          //onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                          onKeyDown={(e:any) => handleKeyDownNextToRef(e, editorReference, handleSubmit, true)}
                          isValid={touched.solutionDuration && !errors.solutionDuration} 
                          isInvalid={!!errors.solutionDuration}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.solutionDuration}
                        </Form.Control.Feedback>
                    </FormGroup>
                    </Row>
                    </Col>
                    </Form.Row> 
                    <Form.Row>
                    <FormGroup as={Col} lg={12} controlId="solText">
                      <FormLabel>Popis řešení</FormLabel>
                        {showSolText &&
                        <TextEditor
                          setFieldValue={(val) => setFieldValue("solText", val, true)}
                          value={values.solText}
                          refEditor={editorReference}
                        />}
                        <div className="invalid-feedback" style={{display: 'block'}}>{errors.solText}</div>
                        <Form.Control.Feedback type="invalid">
                          {errors.solText}
                        </Form.Control.Feedback>
                        <div>
                          <LoadFreeText 
                            setFieldValue={setFieldValue} 
                            showFormField={setShowSolText} 
                            pole="solText" 
                            originalText={values.solText!}  
                            handleKeyDown={handleKeyDownCtrlEnter}
                            handleSubmit={handleSubmit}
                            />
                        </div>
                        
                    </FormGroup>
                    </Form.Row>
                    <FormGroup controlId="isFinished">
                      <Form.Row>
                      <FormLabel  column lg={3}>Nastavit kontakt jako vyřešený</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isFinished"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isFinished}
                          //tabIndex={undefined}
                          onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                          isValid={touched.isFinished && !errors.isFinished} 
                          isInvalid={!!errors.isFinished}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isFinished}
                        </Form.Control.Feedback>
                        </Col>
                        </Form.Row>
                    </FormGroup>
                    <>
                    {<Button 
                      variant='secondary' 
                      size='sm' 
                      title='Fakturovat řešení'
                      style={{lineHeight: '1.15', minWidth: '230px', marginBottom: '10px'}} 
                      onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                      onClick={(e) => { setShowInvoice(!showInvoice); }}
                      >{!showInvoice ? <span><div style={{float:'right'}}>{arrowDown}</div>Fakturace</span>:<><div style={{float:'right'}}>{arrowUp}</div>Fakturace</>}
                    </Button>}
                    <br />
                    </>
                    {showInvoice &&
                    <>
                    <Form.Row>
                    <strong>Podklady pro fakturaci</strong><br />
                    </Form.Row>
                    <FormGroup controlId="isFinished">
                      <Form.Row>
                      <FormLabel  column lg={3}>Fakturovat řešení</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="toInvoice"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.toInvoice}
                          //tabIndex={undefined}
                          onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                          isValid={touched.toInvoice && !errors.toInvoice} 
                          isInvalid={!!errors.toInvoice}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.toInvoice}
                        </Form.Control.Feedback>
                        </Col>
                        </Form.Row>
                    </FormGroup>
                    
                    
                    </>}



                    <hr />
                    </>
                    }


                  <>
                    {<Button 
                      variant='secondary' 
                      size='sm' 
                      title='Odpovídá / termín (Alt+J)'
                      style={{lineHeight: '1.15', minWidth: '230px', marginBottom: '10px'}} 
                      onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                      onClick={(e) => { setShowSolve(!showSolve); }}
                      >{!showSolve ? <span><div style={{float:'right'}}>{arrowDown}</div>Odpovídá / termín</span>:<><div style={{float:'right'}}>{arrowUp}</div>Odpovídá / termín</>}
                    </Button>}
                    <br />
                  </>
                  

                    {showSolve &&
                    <>
                    <Form.Row>
                    <strong>Odpovědná osoba a termín</strong><br />
                    </Form.Row>
                    <Form.Row>
                    <FormGroup as={Col} lg={3} md={4} sm={6} xs={12} controlId="solverUserId">
                      <FormLabel>Osoba</FormLabel>
                        <Form.Control as="select" 
                          name="solverUserId" 
                          onChange={(e) => {handleChange(e); if (e.target.value !== '') { setFieldValue('isFinished', false) } else {setFieldValue('isFinished', true)} }}
                          value={values.solverUserId!}
                          isInvalid={!!errors.solverUserId}

                          //tabIndex={undefined}
                          onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                          >
                            <option value="">--- Vyberte ---</option>
                            {
                            supportHedgehogs
                            }
                            <option value="" disabled>--- NEAKTIVNÍ UŽIVATELÉ ---</option>
                            {
                            supportHedgehogsInactive
                            }
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.solverUserId}
                        </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} lg={3} md={3} sm={6} xs={12}  controlId="deadLineDate">
                      <FormLabel>Termín</FormLabel>
                        <FormControl 
                          type="datetime-local"
                          name="deadLineDate"
                          value={values.deadLineDate!}
                          onChange={(e) => {handleChange(e); setFieldValue('isFinished', false) }}
                          //onChange={handleChange}
                          //tabIndex={undefined}
                          onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                          isValid={touched.deadLineDate && !errors.deadLineDate} 
                          isInvalid={!!errors.deadLineDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.deadLineDate}
                        </Form.Control.Feedback>
                    </FormGroup> 
                    </Form.Row>
                    </>
                    }
                  
                  </>}

                    
                  
                  </Col>
                  <Col>
                    <strong>Téma kontaktu</strong><br />
                    {rootDialsStore.dialsSupportThemes.slice().sort(function(a:any, b:any) {
                      var sort1A = a.name === 'Stereo' ? 2 : a.name === 'Duel' ? 1 : 999; 
                      var sort1B = b.name === 'Stereo' ? 2 : b.name === 'Duel' ? 1 : 999; 

                      if (sort1A! < sort1B!) {
                        return -1;
                      }
                      if (sort1A! > sort1B!) {
                        return 1;
                      }
                      return 0;
                    }).map(dth => (
                      
                        <Button key={dth.id} block size="sm" style={{lineHeight: '1.15'}} variant={themes.includes(dth.id) ? "warning" : "light"} onClick={() => {themes.includes(dth.id) ? deleteTheme(dth.id) : addTheme(dth.id)}}>{dth.name}</Button>
                      
                    ))}
                    

                  </Col>
                  </Form.Row>
                  
                     
                             
                  
                  {error}
                  <br />
                  <ButtonGroup className="btn-block">
                    <Button type="submit" variant="success" disabled={!(isValid) || loading} >{loading ? loadingFile ? 'Uloženo. Nahrávám soubory...':'Ukládám...' : 'Uložit kontakt'}</Button>
                    <Button style={{maxWidth: '250px'}} variant="danger" onClick={() => { handleCloseMain(); }}>Zrušit</Button>
                  </ButtonGroup>
                </Form>

              )}
            </Formik>  
            
            </Alert>
          </div>
          </>}




          <Modal 
            show={show} 
            onHide={handleClose} 
            backdrop="static"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
            centered>
            <Modal.Header closeButton>
              <Modal.Title>
                Detail zákazníka {opening !== true &&<Badge variant='secondary'>{customer?.accountNumber}</Badge>}
                &nbsp;<a target='_blank' href={'#'+ROUTES.dashboard.getLink()} rel="noopener noreferrer"><i className="fas fa-external-link-alt"></i></a>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
              {opening &&<div style={{textAlign: 'center'}}><Spinner animation="border" variant="secondary" /></div>}
              {!opening &&
              <Container fluid>
                
                <Row>
                  <Col style={{minWidth:'300px', marginTop: '25px'}}>
                    
                    <CustomersDashboardInformation />
                    <br />
                    <CustomersDashboardDeliveryAddress />
                  </Col>
                  <Col style={{minWidth:'300px', marginTop: '25px'}}>
                    <CustomersDashboardContacts />
                  </Col>
                </Row>
                
                
                <br /><br />
                <CustomersDashboardLicenses />
                <br />
                {rights && <>
                
                
                  <CustomersDashboardNotes />
                
                <br /><br />
                </>}
                <CardDeck>
                  {rights && <CustomersDashboardCategories />}
                </CardDeck>
                <br /><br />
              </Container>}
             
            </Modal.Body>
          </Modal>


          <Modal 
            show={showNotes} 
            onHide={handleCloseNotes} 
            size="lg"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
            centered>
            <Modal.Header closeButton>
              <Modal.Title>
                Poznámky s upozorněním
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="showMobile">
                {notes.map(notes => (
                  <div key={notes.id}>
                  <Card>
                    <Card.Body style={!notes.isActive ? {} : notes.isNotify ? {fontWeight: 'bold'}: {}}>
 
                          <div className="float-right">
                            <Button size="sm" onClick={() => { removeWarningFromNote(notes.id!) }} variant="outline-primary">{loadingNoteId === notes.id ?<><Spinner size='sm' animation='border'></Spinner>&nbsp;</>:'Zrušit upozornění'}</Button>
                          </div>
                          <div className="float-right"><span style={{marginLeft: '10px'}}></span></div>
                      
                      <div style={{minWidth: '150px', float: 'left'}}>
                        {moment(notes.date).format("DD.MM.YYYY")} (<span title={'Naposledy změnil: ' + rootStore.hedgehogUsers.find(hu => hu.id === notes.userId)?.fullName}>{rootStore.hedgehogUsers.find(hu => hu.id === notes.authorId)?.fullName}</span>)
                        <br />
                        {!notes.isActive && <><Badge variant="secondary">Neaktivní</Badge>&nbsp;&nbsp;</>}{notes.note}
                        <br />
                      </div>
                    </Card.Body>
                  </Card>
                  <br />
                  </div>

                ))}
                <br />
              </div>

              <div className="showWeb">
                <Table hover>
                  <thead>
                    <tr className="jsw-table-head">
                      <th className="jsw-table-head" style={{maxWidth: '150px'}}>Datum</th>
                      <th className="jsw-table-head">Poznámka</th>
                      <th className="jsw-table-head">Pořídil</th>
                      <th className="jsw-table-head">Akce</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notes.map(notes => (
                      
                      
                      <tr key={notes.id} style={!notes.isActive ? {} : notes.isNotify ? {fontWeight: 'bold'}: {}}>
                        <td style={{width: '100px'}}>{moment(notes.date).format("DD.MM.YYYY")}</td>
                        <td>{notes.note}</td>
                        <td style={{width: '190px'}} title={'Naposledy změnil: ' + rootStore.hedgehogUsers.find(hu => hu.id === notes.userId)?.fullName}>{rootStore.hedgehogUsers.find(hu => hu.id === notes.authorId)?.fullName}</td>
                        <td style={{width: '180px'}}><Button size="sm" onClick={() => { removeWarningFromNote(notes.id!) }} variant="outline-primary">{loadingNoteId === notes.id ?<><Spinner size='sm' animation='border'></Spinner>&nbsp;</>:'Zrušit upozornění'}</Button></td>
                      </tr>
                          


                    ))}
                  </tbody>
                </Table>  
              </div>
            </Modal.Body>
          </Modal>


          </>



return ( 
  <>
  {(insertNewContact === false || (insertNewContact === true && props.editing === true)) &&<><Button variant={props.editing===true?'primary':'success'} size="sm" onClick={async () => {await deleteAllThemes();  await startSequenceMain()}} >{props.editing===true?<>Upravit</>:'+ Přidat nový kontakt'}</Button></>}
  {props.editing===true ?
    <Modal 
      show={insertNewContact} 
      onHide={handleCloseMain} 
      backdrop="static"
      dialogClassName="modal-90wf"
      style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Úprava kontaktu
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      {contactForm}

      </Modal.Body>
    </Modal>
    :
    <>{contactForm}</>

  }

    <Modal 
      show={showFile} 
      onHide={handleCloseFile} 
      size="lg"
      backdrop="static"
      //aria-labelledby="example-custom-modal-styling-title"
      aria-labelledby="contained-modal-title-vcenter"
      //dialogClassName="modal-90w"
      style={{backgroundColor: 'rgba(128, 128, 128, 0.5)'}}
      >
      <Modal.Header closeButton>
        <Modal.Title>Přidání souboru</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5><strong>Přidat soubor</strong></h5>
        <Alert variant="success" show={showInfoFile}>
          <strong>Soubor byl úspěšně přidán!​</strong>
        </Alert> 
        <div className={showFormFile} >
          {showFormFile === '' &&
          <Formik
            validationSchema={schemaAddFile}
            initialValues={{ 
              file: '', 
              note: ''
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                handleInsertFile(values.file!, values.note!)
                setSubmitting(false);
              }, 400);
            }}
            validateOnMount
            validateOnChange
          >
            {({ 
              handleSubmit,
              handleChange,
              validateForm,
              values,
              touched,
              isValid,
              errors,
              isSubmitting }) => (
              <Form onSubmit={handleSubmit}>  
                <FormGroup controlId="file">
                  <Form.Row>
                    <FormLabel column lg={3}>Soubor</FormLabel>
                    <Col>
                    
                    <label style={{border: 'none',display: 'inline-block', cursor: 'pointer', marginRight: '12px'}}>
                    <input
                      type="file"
                      id="file" 
                      name="file"
                      className="form-control"
                      value={values.file!} 
                      onChange={async (e) => {await handleChange(e); await setFile(e); await validateForm() }}
                      style={{display: 'none'}}
                    />
                        <span className='btn btn-primary btn-sm'>Výběr souboru...</span> 
                    </label>
                    {fileName}


                    
                      <div className="invalid-feedback" style={{display: 'block'}}>{errors.file}</div>
                      <Form.Control.Feedback type="invalid">
                        {errors.file}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Row>  
                </FormGroup>  
                <FormGroup controlId="note">
                  <Form.Row>
                    <FormLabel column lg={3}>Popis</FormLabel>
                    <Col>
                      <FormControl 
                        type="text"
                        name="note"
                        value={values.note!}
                        onChange={handleChange}
                        isValid={touched.note && !errors.note} 
                        isInvalid={!!errors.note}
                      />
                      
                      <Form.Control.Feedback type="invalid">
                        {errors.note}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Row>  
                </FormGroup>
                            
                {error}
                <br />
                <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Přidávám soubor...' : 'Přidat soubor'}</Button>
                
              </Form>
            )}
          </Formik>  
          }
        </div>
      </Modal.Body>

    </Modal>




  </>
)

}

export default withRouter(observer(SupportContactsAdd))
